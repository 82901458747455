import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

import '../App.css';
import { getUserFlashcards, updateUserFlashcard } from '../api';

const FlashcardsPage = () => {
  const [ searchParams ] = useSearchParams();
  const [ flashcards, setFlashcards ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const userId = searchParams.get("user");

  const fetchWords = useCallback(async () => {
    if (userId) {
      setFlashcards(await getUserFlashcards(userId));
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchWords();
  }, [fetchWords]);

  const onFlashcardChange = async (flashcard) => {
    const clone = [...flashcards];
    const index = clone.findIndex(f => f.id === flashcard.id);
    clone[index].is_active = !clone[index].is_active;
    setFlashcards(clone);
    await updateUserFlashcard(userId, flashcard.id, { is_active: clone[index].is_active })
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src="/aviva-logo.png" className="App-logo" alt="logo" />
        <h1 className="text-5xl font-bold text-black text-left">aviva</h1>
      </header>
      <div className='App-body'>
        <h1 className="text-3xl font-bold underline pt-5">
          המילים שלך
        </h1>
        <br />
        <div className='flashcards'>
          {isLoading &&
            <HashLoader
              color='#FFFFFF'
            />
          }
          {flashcards.map((flashcard, index) => (
            <div key={index} className='flashcard'>
              <h2 style={{ 
                textDecoration: flashcard.is_active ? 'none' : 'line-through',
               }}>{flashcard.word.eng}</h2>
              <input
                type='checkbox'
                checked={!flashcard.is_active}
                onChange={async () => onFlashcardChange(flashcard)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default FlashcardsPage;