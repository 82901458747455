import '../App.css';

const MainPage = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/aviva-logo.png" className="App-logo" alt="logo" />
        <h1 className="text-5xl font-bold text-black text-left">aviva</h1>
      </header>
      <div className='App-body'>
      </div>
    </div>
  );
};
export default MainPage;
