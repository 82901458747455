import { BrowserRouter, Route, Routes } from "react-router-dom";

import FlashcardsPage from './pages/FlashcardsPage';
import MainPage from "./pages/MainPage";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/flashcards" element={<FlashcardsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
