import axios from 'axios';
// import crashlytics from '@react-native-firebase/crashlytics';
// import { Alert } from 'react'; // not exactly compatible with original react-native code

const JSON_CONTENT_TYPE_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const { REACT_APP_SERVER_HOST } = process.env

export const reportError = async (error) => {
  for (let i = 0; i < 2; i++) {
    try {
      console.log(`api ----- reporting Error [${error?.message}]`);
      if (REACT_APP_SERVER_HOST.includes('test')) {
        // Alert.alert(
        //   'Oh no!',
        //   'Something went wrong 😔\nAn error was reported to the test server',
        //   [{
        //     text: 'OK',
        //     onPress: () => { },
        //     style: 'default',
        //   }]
        // );
      }
      // crashlytics().recordError(error);
      await axios.post(
        `${REACT_APP_SERVER_HOST}/api/public/app/reportError`,
        {
          error: {
            message: error?.message,
            stack: error?.stack,
          },
        },
        JSON_CONTENT_TYPE_HEADERS
      );
      break;   // error reported successfully
    } catch (error) {
      console.error(`api ----- Failure on attempt to send error report: ${error}`);
    }
    await delay(3000);  // try again in 3 seconds
  }
};

const API_RETRIES_DELAYS = [100, 500, 1000, 2000, 5000, 1];
const RETRY_ERROR_THRESHOLD = 2000;

const apiRequestWrapper = async (requestName, request) => {
  for (let i = 0; i < API_RETRIES_DELAYS.length; i++) {
    const retry = i + 1;
    const retryDelay = API_RETRIES_DELAYS[i];
    const isExhausted = retry === API_RETRIES_DELAYS.length - 1;
    try {
      console.log(`api ----- ${requestName}${retry > 1 ? ` (attempt ${retry})` : ''}`);
      const response = await request();
      if (response.status < 300) {
        return response.data;
      } else if (response.status <= 500) { // 4xx & 500 errors
        const error = new Error(`Request '${requestName}' failed with status ${response.status}`);
        // crashlytics().recordError(error);
        reportError(error);
        return;   // no point in retrying when status is 4xx
      }
      if (retryDelay >= RETRY_ERROR_THRESHOLD) {
        const error = new Error(`Request '${requestName}' failed with status ${response.status}`);
        // crashlytics().recordError(error);
        reportError(error);
      }
      if (isExhausted) {
        console.log(`api ----- ${requestName}: Exhausted retries`);
        return;
      }
      await delay(retryDelay); 
    } catch (error) {
      if (error.response && error.response.status <= 500) {   // 4xx & 500 errors
        error.message = `'${requestName}' ${error.message}`;
        throw error;
      }
      if (retryDelay >= RETRY_ERROR_THRESHOLD) {
        // crashlytics().recordError(error);
        reportError(error);
      }
      if (isExhausted) {
        console.log(`api ----- ${requestName}: Exhausted retries`);
        throw error;
      }
      await delay(retryDelay);
    }
  }
}

export const getUserFlashcards = async (userId) => apiRequestWrapper(
  `get user flashcards`,
  async () => axios.get(
    `${REACT_APP_SERVER_HOST}/user/${userId}/flashcards`
  )
);

export const updateUserFlashcard = async (userId, flashcardId, data) => apiRequestWrapper(
  `update user flashcard ${flashcardId}`,
  async () => axios.patch(
    `${REACT_APP_SERVER_HOST}/user/${userId}/flashcards/${flashcardId}`,
    data,
    JSON_CONTENT_TYPE_HEADERS
  )
);